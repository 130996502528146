#title-alert {
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
}

#container-initial {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    position: absolute;
    z-index: 20;
    top: 10%;
    left: 50%;
    /* transform: translate(-50%); */
    background-color: #FFFCBD;
    border: solid rgb(231, 175, 70) 1px;
    color: #404040;
    box-shadow: 5px 5px 15px -2px #707070;
    max-width: 250px;
    font-size: 13px;
    padding: 10px;
    font-family: sans-serif;

}

#got-it {
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
}

#exit {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    font-size: 15px;
}