.area:hover {
    /* padding: 500px; */
    border: dotted rgb(187, 187, 187) 2px;

}

.area {
    /* border: dotted rgb(187, 187, 187) 2px; */
    /* transform: translate(-50%, -50%); */
}

.container-area { 
    border: solid;
    padding: 30px;
    position: absolute;
    /* transform: translate(-50%, -100%); */
    /* z-index: 16; */
}