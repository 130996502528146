.pop-up {
    border: solid orange;
    background-color: yellow;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: default;
    z-index: 15;
    opacity: 1;
    transition: opacity 0.5s;
    /* transform: translate(50%); */
    /* padding: 50px; */
    /* opacity: 0; */
    /* transform-origin: left top; */
}

.pop-up:hover {
    opacity: 1;
}