.map-img {
    /* height: 100vh; */
    /* max-width: 950px; */
    /* width: 100vw; */
    /* padding: 50px; */
    width: 100%;
    display: block;
    z-index: 1;
}

.container-map {
    z-index: 1;
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
}

.clickable {
    position: absolute;
    z-index: 5;
}

.container-links {
    height: 100vh;
    /* position: relative; */
    position: absolute;
    top: 50%;
    height: 100%;
    transform: translateY(-50%);
    /* z-index: 6; */
}
.container-img {
    /* height: 95vh; */
    /* height: 100vh; */
    overflow: hidden;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* @media (max-aspect-ratio: 1/1.299){ */
    /* .container-links { */
        /* height: 100vh; */
        /* width: 100vw; */
    /* } */
    /* .map-img { */
        /* height: auto; */
        /* width: 100vw; */
    /* } */
    /* .container-img { */
        /* height: fit-content; */
        /* display: block; */
    /* } */
    /* .container-map { */
        /* position: static; */
        /* transform: translate(0); */
    /* } */
/* } */

.mapInteraCation {
    height: 95vh;
}



.area {
    /* border: solid black 4px; */
    position: absolute;
    z-index: 10;
    transform: translate(-50%, -50%);
}

.inner-container-img {
    position: relative;
    height: fit-content;
    width: fit-content;
}

.tool-tip-thing {
    position: absolute;
    z-index: 20;
    top: 10%;
    left: 50%;
    transform: translate(-50%);
    background-color: #FFFCBD;
    border: solid rgb(231, 175, 70) 1px;
    color: #404040;
    box-shadow: 5px 5px 15px -2px #707070;
    max-width: 250px;
    font-size: 13px;
    padding: 10px;
    font-family: sans-serif;
}

.tool-tip-thing a {
    color:  #7F7F7F;
    font-family: sans-serif;
    word-wrap: break-word;
    font-size: 13px;
    text-decoration: none;
}